import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import _forEach from "lodash/forEach"
import _isEmpty from "lodash/isEmpty"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import { addressDisplay, getStorage, setStorage } from "../utils/index"
import ButtonField from "./common/form/ButtonField"
import { STEP_SECOND_SCREEN, OFFER_ADDRESS_VERIFY_SCREEN } from "../constant"

const StepOneComponent = ({ data = {}, locationPath = false, title = "" }) => {
  const introData = useStaticQuery(graphql`
    query {
      allStrapiIntroSection {
        nodes {
          menImage {
            alternativeText
            localFile {
              childImageSharp {
                fluid(quality: 0, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          headerMenImage
          imagePositionRight
        }
      }
    }
  `)
  const [searchText, setSearchText] = useState("")
  const menImage = introData.allStrapiIntroSection.nodes[0].menImage.localFile.childImageSharp.fluid
  const headerMenImage = introData.allStrapiIntroSection.nodes[0].headerMenImage
  const rightMenImage = introData.allStrapiIntroSection.nodes[0].imagePositionRight

  const onChangeText = e => {
    setSearchText(e.target.value)
    setStorage("addressSelect", false)
  }
  useEffect(() => {
    document.getElementById("pac-input2").value = searchText
  }, [searchText])

  useEffect(() => {
    document.getElementById("pac-input1").focus();
    let focusValue = getStorage("focusValue")
    if (focusValue) {
      document.getElementById("pac-input1").focus();
      var x = window.matchMedia("(max-width: 600px)")
      var element = null
      if (x.matches) {
        element = document.getElementById('pac-input1');
      } else {
        element = document.getElementById('addressInput');
      }
      element.classList.add('wobble');
      setStorage("focusValue", false)
      setTimeout(() => {
        element.classList.remove('wobble');
      }, 500);
    }
  })

  const onSetValue = React.useCallback((place) => {
    if (!_isEmpty(place)) {
      let obj = {}
      obj = {
        city: "",
        google_county: "",
        latitude: place.geometry?.location.lat(),
        longitude: place.geometry?.location.lng(),
        state: "",
        street_address: place.name,
        zip: "",
      }
      place.address_components.map(val => {
        if (val.types.includes("locality")) {
          obj = {
            ...obj,
            city: val.short_name,
          }
        }
        if (val.types.includes("administrative_area_level_2")) {
          obj = {
            ...obj,
            google_county: val.short_name,
          }
        }
        if (val.types.includes("administrative_area_level_1")) {
          obj = {
            ...obj,
            state: val.short_name,
          }
        }
        if (val.types.includes("postal_code")) {
          obj = {
            ...obj,
            zip: val.short_name,
          }
        }
        return obj
      })
      let initalValue = getStorage("formData")
      const { street_address = " ", city = " ", state = " ", zip = " " } = obj
      let address = `${addressDisplay(street_address)} ${addressDisplay(city)} ${addressDisplay(state)} ${zip} USA`
      setStorage("formData", {
        ...initalValue,
        ...obj,
        address,
        unit: null,
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        currently_listed: null,
        require_repairs: null,
        current_condition: null,
        asking_price: null,
        urgency: null,
        time_to_call: null,
        reason_to_sell: null,
        property_type: null,
        here_about_type: null,
      })
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "address_submit",
        dtL_address_submit: {
          address: address,
          branch: process.env.GATSBY_BRANCH
        },
      })
      let selectValue = getStorage("addressSelect")
      if (!selectValue) {
        if (title === "get-offer") {
          navigate(OFFER_ADDRESS_VERIFY_SCREEN)
        } else {
          navigate(STEP_SECOND_SCREEN)
        }
      }
    }
  }, [title])

  useEffect(() => {
    const input = document.getElementsByName("getOfferText")
    setTimeout(() => {
      if (typeof window.google !== "undefined") {
        _forEach(input, item => {
          if (item) {
            let map = new window.google.maps.Map(
              document.getElementById("map"),
              {
                center: {
                  lat: 48,
                  lng: 4,
                },
                zoom: 4,
                disableDefaultUI: true,
              }
            )
            let autocomplete_results_all = document.querySelectorAll(
              ".autocomplete-results"
            )
            let predictValue = []
            _forEach(autocomplete_results_all, autocomplete_results => {
              let service = new window.google.maps.places.AutocompleteService()
              let serviceDetails = new window.google.maps.places.PlacesService(
                map
              )
              let displaySuggestions = function (predictions, status) {
                if (
                  status !== window.google.maps.places.PlacesServiceStatus.OK
                ) {
                  predictValue = []
                }
                let results_html = []
                predictValue = predictions
                if (!_isEmpty(predictValue)) {
                  predictions.forEach(function (prediction) {
                    results_html.push(
                      `<li class="autocomplete-item" data-type="place" data-place-id=${prediction.place_id}><span class="autocomplete-text">${prediction.description}</span></li>`
                    )
                  })
                }
                autocomplete_results.innerHTML = results_html.join("")
                autocomplete_results.style.display = "block"
                let autocomplete_items = autocomplete_results.querySelectorAll(
                  ".autocomplete-item"
                )
                let getBtns = document.getElementsByName("getOfferBtn")
                _forEach(getBtns, getBtn => {
                  getBtn.addEventListener("click", () => {
                    let request = {
                      placeId: predictValue[0]?.place_id,
                      fields: ["name", "geometry", "address_components"],
                    }
                    serviceDetails.getDetails(
                      request,
                      function (place, status) {
                        if (
                          status ===
                          window.google.maps.places.PlacesServiceStatus.OK
                        ) {
                          if (!place.geometry) {
                            console.log("Returned place contains no geometry")
                            return
                          } else {
                            let selectValue = getStorage("addressSelect")
                            let textValue = getStorage("addressText")
                            if (textValue) {
                              if (selectValue) {
                                if (title === "get-offer") {
                                  navigate(OFFER_ADDRESS_VERIFY_SCREEN)
                                } else {
                                  navigate(STEP_SECOND_SCREEN)
                                }
                              } else {
                                onSetValue(place)
                                setSearchText(predictValue[0].description)
                                item.value = predictValue[0].description
                              }
                            }
                          }
                        }
                        autocomplete_results.style.display = "none"
                      }
                    )
                  })
                })
                for (let autocomplete_item of autocomplete_items) {
                  autocomplete_item.addEventListener("click", function () {
                    const selected_text = this.querySelector(
                      ".autocomplete-text"
                    ).textContent
                    const place_id = this.getAttribute("data-place-id")
                    let request = {
                      placeId: place_id,
                      fields: ["name", "geometry", "address_components"],
                    }
                    serviceDetails.getDetails(
                      request,
                      function (place, status) {
                        if (
                          status ===
                          window.google.maps.places.PlacesServiceStatus.OK
                        ) {
                          if (!place.geometry) {
                            console.log("Returned place contains no geometry")
                            return
                          } else {
                            setStorage("addressSelect", true)
                            onSetValue(place)
                          }
                        }
                        setSearchText(selected_text)
                        item.value = selected_text
                        autocomplete_results.style.display = "none"
                      }
                    )
                  })
                }
              }
              item.addEventListener(
                "input",
                debounce(function () {
                  let value = this.value
                  let pattern = /.*[0-9].*/g
                  let testReg = pattern.test(value)
                  value.replace('"', '\\"').replace(/^\s+|\s+$/g, "")
                  if (value !== "" && testReg) {
                    service.getPlacePredictions(
                      {
                        input: value,
                        componentRestrictions: { country: "us" },
                        fields: [
                          "address_components",
                          "geometry",
                          "icon",
                          "name",
                        ],
                      },
                      displaySuggestions
                    )
                    setStorage("addressText", true)
                  } else {
                    setStorage("addressText", false)
                    autocomplete_results.innerHTML = ""
                    autocomplete_results.style.display = "none"
                  }
                }, 150)
              )
            })
          }
        })
      }
    }, 1000)
  }, [title, onSetValue])

  const debounce = (func, wait, immediate) => {
    let timeout
    return function () {
      let context = this,
        args = arguments
      let later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      let callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  }
 
  const onBlur = () => {
    let autocomplete_results_all = document.querySelectorAll(
      ".autocomplete-results"
    )
    setTimeout(() => {
      _forEach(autocomplete_results_all, autocomplete_results => {
        autocomplete_results.style.display = "none"
      })
    }, 400)
  }
  return (
    <div className="text-white stepOneContainer">
      <img src={`//ad.ipredictive.com/d/track/cvt/pixel?acct_id=57292&cache_buster=${Math.floor(Date.now() / 1000)}`} alt="home" style={{display:'none'}} width="1" height="1" />
      <div id="map" className="d-none"></div>
      {(locationPath ? data.locationDetails.headerMenImage : headerMenImage) &&
        <div className={`${locationPath ? data.locationDetails.imagePositionRight ? "locationMenImage" : " locationMenImage leftLocationMenImage"
          : rightMenImage ? "introMenImage" : "introMenImage leftIntroMenImage"}`}>
        <Img fluid={locationPath ? data.locationDetails.menImage.localFile.childImageSharp.fluid : menImage} alt="Tarek El Moussa" />
        </div>
      }
      <div className="text-center mt-2">
        {locationPath
          ? <h1>{`Sell your house in ${data?.locationDetails.location_name}`}</h1>
          : <div className="topHeader">
            <h1>{title === "get-offer" ? "Sell Your House to Tarek" : "Sell Your Home As-Is, For The Best Price Possible."}</h1>
          </div>}
      </div>
      <div className="mt-2 header-2">
        {locationPath
          ? `We've helped over ${data.locationDetails.helped_over} Homeowners in ${data.locationDetails.location_short_name}`
          : title === "get-offer" ? "Step 1. Enter Your Home Address" : "No showings, no repairs, no fees."}
      </div>
      <div id="addressInput" className="mt-3 d-flex justify-content-center address-wrapper shakeInput">
        <ButtonField
          className="btn-primary form-getOfferBtn"
          id="getOfferBtn"
          fieldName="getOfferBtn"
          name={"GET MY FREE OFFER"}
        />
        <input
          id="pac-input1"
          type="text"
          placeholder="Enter your home address"
          onChange={e => onChangeText(e)}
          className="form-control form-getAddressFixedInput shadow-sm shakeInput"
          name="getOfferText"
          autoComplete="off"
          onBlur={() => onBlur()}
        />
        <ul className="autocomplete-results"></ul>
      </div>
    </div>
  )
}

export default StepOneComponent
