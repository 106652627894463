import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import StepOne from "../components/step-1";

export const query = graphql`
  query locations($slug: String!) {
    locationDetails:strapiLocations(slug: { eq: $slug }) {
      location_name
      location_image {
        alternativeText
        localFile {
          childImageSharp {
            fluid(quality: 0, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
      helped_over
      location_short_name
      slug
      whyChooseLeftImage {
        alternativeText
        localFile {
          childImageSharp {
            fluid(quality: 0, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      whyChooseRightImage {
        alternativeText
        localFile {
          childImageSharp {
            fluid(quality: 0, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      step {
        title
        step {
          description
          title
          image {
            alternativeText
            localFile {
              childImageSharp {
                fluid(quality: 0, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      brandImage {
        image {
          alternativeText
          localFile {
            childImageSharp {
              fluid(quality: 0, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      Description
      Title
      testimonialTitle
      chooseTitle {
        title
        description
      }
      How_do_sell {
        title
        step {
          description
          title
        }
      }
      greatTime {
        description
        title
        stat {
          title
          description
        }
      }
      our_mission {
        title
        step {
          description
          title
          image {
            alternativeText
            localFile {
              childImageSharp {
                fluid(quality: 0, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      help_question {
        description
        title
        image {
          alternativeText
          localFile {
            childImageSharp {
              fluid(quality: 0, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      our_mission_description {
        desc
      }
      chooseType {
        title
        list {
          Category
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              fluid(quality: 0, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      menImage {
        alternativeText
        localFile {
          childImageSharp {
            fluid(quality: 0, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      headerMenImage
      imagePositionRight
      SEO {
        metaDescription
        metaTitle
        structuredData
      }
      locationQuickTip {
        Description
        Title
        shortDescription
        youTubeVideoURL
      }
      animationEffect
      updated_at
      published_at
      alterSellYouHome
      alterlocationSell {
        Title
        belowDescription
        description
        locationImage {
          title
          image {
            alternativeText
            localFile {
              childImageSharp {
                fluid(quality: 0) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    allStrapiLocationCommonContent {
      nodes {
        How_do_sell {
          title
          step {
            title
            description
          }
        }
        intro_text
        our_mission {
          title
          step {
            description
            title
            image {
              alternativeText
              localFile {
                childImageSharp {
                  fluid(quality: 0, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        our_mission_description {
          desc
        }
        help_content {
          Title
          help_list {
            description
            title
          }
          help_question {
            description
            title
            image {
              alternativeText
              localFile {
                childImageSharp {
                  fluid(quality: 0, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        repair_content {
          TH1
          TH2
          table_data {
            title
            description
          }
          repair_question {
            description
            title
            image {
              alternativeText
              localFile {
                childImageSharp {
                  fluid(quality: 0, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          title
        }
      }
    }
  }
  `;
const Blogs = ({ data }) => {
  return (
    <Layout locationPath={true} locationData={data}>
      <StepOne data={data} locationPath={true} />
    </Layout>
  );
};

export default Blogs;
